@mixin set-font($type) {
  @if $type == 'header-h1' {
    // font-family: 'Abril Fatface', serif;
    font-family: linotype-didot, serif;
    letter-spacing: .05em;
    font-weight: 700;
    line-height: .8em;
    text-transform: uppercase;
    @include phone {
      font-size: 8.5vw;
    }
    @include tablet-plus {
      font-size: 6.5vw;
    }
  }
  @if $type == 'content' {
    text-transform: uppercase;
    letter-spacing: .5em;
    @include font(10);
  }
  @if $type == 'h2' {
    text-transform: uppercase;
    font-weight: 200;
    letter-spacing: .35em;
    @include scalable-font(24, 16, 1.2);
  }
  @if $type == 'inline-h1' {
    font-family: linotype-didot, serif;
    letter-spacing: .05em;
    font-weight: 700;
    font-size: calc(2vw);
    line-height: 1.2em;
  }
  @if $type == 'blockquote' {
    font-family: linotype-didot, serif;
    font-weight: 700;
    // font-size: calc(3vw);
    @include scalable-font(40, 30, 1.1);
    margin-top: $rhythm * 2;
    margin-bottom: $rhythm * 2;
  }
}

// @mixin overhang-content() {
//   margin-left: -#{$rhythm};
//   margin-right: -#{$rhythm};
// }
