// All colours go here
$black: #2E2E2E;
$actual-black: #000;
$gray: #ccc;
$white: #fff;
$almost-white: #efefef;

$red: #e74242;
$green: #60942e;

// themes / colours
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);

// Fonts
$base-font-size: 18;
$base-line-height: 1.4;

// Vertical Rhythm
$rhythm: $base-line-height + 0rem;

// Structural constants and helper mixins

$design-width: 1440; // reference width from the design
$design-gutter: 0;

// to add a max width for the site enable $fluid-max
// $fluid-max: 1600px;
$fluid-max: false;

$tablet-max: 1100px;
$tablet-portrait: 930px;
$phone-max: 667px;
$phone-portrait: 375px;

$total-cols: 24;
$single-column: percentage(1 / $total-cols);
$default-gutter: percentage($design-gutter / $design-width);
$fallback-width: 1200px; // used in place of 100vw where vw not supported

$item-margin: 10px;

$page-switch-time: 600ms;

@keyframes pulse {
  0%   {
    transform: scale(1);
    // opacity: .8;
  }
  50% {
    transform: scale(1.3);
    // opacity: 1;
  }
  100%   {
    transform: scale(1);
    // opacity: .8;
  }
}



@function phone-inset($negative: false) {
  @if ($negative == true) {
    @return -5px;
  } @else {
    @return 5px;
  }
}

@function tablet-plus-inset($negative: false) {
  @if ($negative == true) {
    @return -4vw;
  } @else {
    @return 4vw;
  }
}

@mixin grid-container($prop:null) {
  @include phone {
    padding-left: phone-inset();
    padding-right: phone-inset();
  }
  @include tablet-plus {
    // padding-left: tablet-plus-inset() * 2;
    // padding-right: tablet-plus-inset() * 2;
    @include prefix(2.5, $prop:padding-left);
    @include suffix(2.5, $prop:padding-right);
  }

  // @if ($fluid-max) {
  //   max-width: $fluid-max;
  //   // min-width: $min-fluid-width - phone-inset() * 2;
  //   margin: 0 auto;
  // }
}

@mixin gutters($gutter: $default-gutter, $parent-cols: $total-cols) {
  // Add left/right margin according to the grid gutter width.
  @if ($default-gutter != 0) {
    $factor: $total-cols / $parent-cols;
    margin-left: $gutter * $factor;
    margin-right: $gutter * $factor;
  }
}

@function columns($cols, $parent-cols: $total-cols, $gutter: $default-gutter) {
  $factor: $total-cols / $parent-cols;
  @return ($cols * $single-column - $gutter * 2) * $factor;
}

@mixin cols($cols, $parent-cols: $total-cols, $gutter: $default-gutter) {
  // Set width to the specified number of cols, with gutters.
  width: columns($cols, $parent-cols, $gutter);
  @include gutters($gutter, $parent-cols);
}

// @mixin ib-grid($args...) {
//   // wrapper for cols() which also makes the element an inline-block column
//   display: inline-block;
//   vertical-align: top;
//   @if length($args) > 0 {
//     @include cols($args...);
//   }
// }

@mixin prefix($cols: 0, $parent-cols: $total-cols, $gutter: $default-gutter,
              $prop: margin-left) {
  // Add left margin of the specified number of cols
  $factor: $total-cols / $parent-cols;
  #{$prop}: (($single-column * $cols) + $gutter) * $factor;
}

@mixin suffix($cols: 0, $parent-cols: $total-cols, $gutter: $default-gutter,
              $prop: margin-right) {
  // Add right margin of the specified number of cols
  $factor: $total-cols / $parent-cols;
  #{$prop}: (($single-column * $cols) + $gutter) * $factor;
}

@function grid-px($cols, $gutter: $default-gutter) {
  // Calculate the pixel width of a fluid grid element, at $design-width
  @return round(($cols / $total-cols - 2 * $gutter / 100%) * $design-width);
}

// media
@mixin fluid-plus {
  @if ($fluid-max) {
    $min: $fluid-max + 1;
    @media screen and (min-width: $min) {
      @content;
    }
  }
}
@mixin desktop {
  $min: $tablet-max + 1;
  @if ($fluid-max) { $max: $fluid-max; }
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin desktop-plus {
  $min: $tablet-max + 1;
  @media screen and (min-width: $min) {
    @content;
  }
}
// mobile: encompassing tablet & phone
@mixin tablet-minus {
  $max: $tablet-max;
  @media screen and (max-width: $max) {
    @content;
  }
}
@mixin tablet {
  $min: $phone-max + 1;
  $max: $tablet-max;
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin tablet-plus {
  $min: $phone-max + 1;
  @media screen and (min-width: $min) {
    @content;
  }
}
@mixin tablet-landscape-plus {
  $min: $tablet-portrait + 1;
  @media screen and (min-width: $min) {
    @content;
  }
}
@mixin tablet-landscape {
  $min: $tablet-portrait + 1;
  $max: $tablet-max;
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin tablet-portrait {
  $min: $phone-max + 1;
  $max: $tablet-portrait;
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin tablet-portrait-minus {
  $max: $tablet-portrait;
  @media screen and (max-width: $max) {
    @content;
  }
}
@mixin phone {
  $max: $phone-max;
  @media screen and (max-width: $max) {
    @content;
  }
}
@mixin phone-portrait {
  $max: $phone-portrait;
  @media screen and (max-width: $max) {
    @content;
  }
}
