// Box sizing //
*,
*::before,
*::after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 $rhythm;
  font-size: 1em;
  font-weight: 400;
}

strong {
  font-weight: 500;
}

nav {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
  color: $black;
  &.cta {
    display: inline-block;
    margin-top: $rhythm;
    @include set-font('content');
    border: 1px solid;
    padding: 10px 20px;
    @include hover-opacity;
    // background: $white;
  }
}

p {
  margin: 0 0 $rhythm;
}

figure,
blockquote {
  margin: 0;
}

.phone-only {
  @include tablet-plus {
    display: none;
  }
}

.tablet-plus-only {
  @include phone {
    display: none;
  }
}

i.close {
  cursor: pointer;
  position: relative;
  display: block;
  height: 1em;
  width: 1em;
  // transform: translateZ(0);
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    transform: rotate(45deg);
    border-top: 2px solid $black;
    top: calc(50% - 1px);
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    transform: rotate(-45deg);
    border-bottom: 2px solid $black;
    bottom: calc(50% - 1px);
  }
  // @include phone {
  //   height: 20px;
  //   width: 20px;
  // }
  // @include tablet-plus {
  //   height: 24px;
  //   width: 24px;
  // }
}

.page {
  position: relative;
  .next-page {
    padding-bottom: $rhythm;
    text-transform: uppercase;
    @include font(30);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    text-align: center;
  }
  &.loading-more {
    .next-page {
      opacity: 1;
    }
  }
}

// .video-player {
//   position: relative;
//   height: 0;
//   padding-top: 56%;
//   .image {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 1;
//   }
//   .player {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center;
//     z-index: 2;
//     // background-color: $black;
//     .play {
//       position: absolute;
//       top: 0;
//       left: 0;
//       right: 0;
//       bottom: 0;
//       z-index: 1;
//       background-color: rgba($black, .2);
//       // background-image: url('../i/play.svg');
//       background-repeat: no-repeat;
//       background-position: center;
//
//       @include phone {
//         background-size: 5rem;
//       }
//       @include tablet-plus {
//         background-size: 6.5rem;
//       }
//     }
//   }
//   iframe {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 3;
//   }
// }
.image {
  // article & {
  //   // background: $tan-wash;
  // }
  // article .gallery & {
  //   background: $almost-white;
  // }
  // article aside & {
  //   background: $white;
  // }
  img {
    &:not([src]) {
      opacity: 0;
    }
    // opacity: 0;
    // transition: opacity 400ms;
    // .initialised &,
    // .no-js & {
    //   opacity: 1;
    // }
  }
  &[style*="padding-top"] {
    margin: 0;
    height: 0;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
  .image-background {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  &.lazyload {
    &::before {
      opacity: 1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background-color: $primary-color;
      transition: 400ms opacity;
      .state-unfocus-navigation & {
        opacity: .1;
        transition: 700ms opacity;
      }
    }
    > * {
      opacity: 1;
      transform: translateZ(0); //hardware accelerated
    }
    html:not(.state-initialised) & {
      > * {
        opacity: 0;
      }
    }
    &.lazyloading {
      > * {
        opacity: 0;
      }
    }
    &.lazyloaded {
      // &::before {
      //   background-color: transparent;
      // }
      > * {
        opacity: 1;
        transition: 700ms background-color, opacity 700ms;
      }
    }
  }
  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      position: relative;
    }
  }
  &.restrict-width {
    img {
      height: auto;
      width: 100%;
    }
  }
  &.restrict-height {
    height: 100%;
    img {
      height: 100%;
      width: auto;
      position: relative;
    }
  }
  &.grayscale img {
    filter: grayscale(100%);
  }
}

.blocks {
  position: relative;
  z-index: 1;
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  > * {
    display: inline-block;
    vertical-align: top;
    body.state-show-caption & {

    }
  }
}

.type-text,
.introduction,
.questions,
.contact-details,
.words,
.other-words {
  margin-top: 10vh;
  margin-bottom: 14vh;
  &.center-text {
    text-align: center;
    p,
    blockquote {
      margin-left: auto;
      margin-right: auto;
    }
  }
  h2 {
    @include set-font('h2');
  }
  p {
    max-width: 28em;
  }
  a:not(.cta) {
    @include hover-underline;
  }

  blockquote {
    @include set-font('blockquote');
      max-width: 15em;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
  }
  @include phone {
    // @.type-text
    @include prefix(1);
    @include suffix(1);
  }
  @include tablet-plus {
    // @.type-text
    @include cols(12);
    &.display-right {
      padding-left: #{tablet-plus-inset() / 2};
      color: $black;
      &:not(.no-margin) {
        @include prefix(12);
      }
    }
    &.display-left {
      padding-right: #{tablet-plus-inset() * 2};
      color: $black;
      &:not(.no-margin) {
        @include suffix(12);
      }
    }
    &.no-margin {
      vertical-align: middle;
    }
  }
}
.introduction {
  margin-top: 14vh;
}
.type-image,
.primary-image {
  position: relative;
  width: 100%;
  .image {
    transition: 300ms transform 300ms;
  }
  .figcaption-trigger + .image {
    cursor: pointer;
  }
  & .figcaption-trigger {
    transform: translateZ(0); //hardware accelerated
    content: '';
    position: absolute;
    z-index: 100;
    bottom: 15px;
    left: 15px;
    height: 15px;
    width: 15px;
    background: $secondary-color;
    border-radius: 50%;
    transform-origin: 50% 50%;
    cursor: pointer;
    body:not(.state-focus-navigation) & {
      animation: pulse 1000ms infinite;
    }
    // @include phone {
    //   left: 50%;
    //   margin-left: -7px;
    // }
  }
  &:nth-child(odd) {
    body:not(.state-focus-navigation) & {
      animation-delay: 500ms; // half of animation
    }
  }
  figcaption,
  .share-links {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 5em;
    opacity: 0;
    transition: 400ms opacity;
    padding-bottom: 15px;
    color: $black;
    > * {
      max-width: 20em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  figcaption {
    a:not(.cta) {
      @include hover-underline;
    }
  }
  .share-links {
    text-transform: uppercase;
    letter-spacing: .25em;
    @include font(10);
    a {
      @include hover-opacity;
    }
  }
  body.state-show-caption &.state-selected-caption {
    figcaption,
    .share-links {
      opacity: 1;
      transition: 400ms opacity 400ms;
    }
  }
  .share-links {
    a {
      display: inline-block;
    }
  }
  @include phone {
    // @&.type-image / .primary-image
    margin-bottom: phone-inset();
    figcaption,
    .share-links {
      margin-top: phone-inset();
      padding-left: phone-inset();
      padding-right: phone-inset();
    }
    .share-links {
      left: auto;
      right: 0;
      text-align: right;
      a {
        margin-left: $rhythm / 2;
      }
    }
    figcaption {
      margin-top: $rhythm * 2;
    }
  }
  @include tablet-plus {
    // @&.type-image / .primary-image
    margin-bottom: tablet-plus-inset();
    &.primary-image,
    &.display-100 {
      figcaption {
        color: $black;
      }
    }
    &.display-75-left {
      @include cols(18)
      @include suffix(6);
    }
    &.display-75-right {
      @include cols(18)
      @include prefix(6);
      figcaption {
        @include prefix(6.5, 18);
        @include cols(11.5, 18);
      }
    }
    &.display-75-center {
      @include cols(18)
      @include prefix(3);
    }
    &.display-50 {
      width: calc(50% - #{tablet-plus-inset() / 2});
      &.left {
        margin-right: #{tablet-plus-inset() / 2};
      }
      &.right {
        margin-left: #{tablet-plus-inset() / 2};
      }
    }
    &.display-50-left,
    &.display-50-right {
      width: 50%;
    }
    &.display-50-left {
      margin-right: 50%;
    }
    &.display-50-right {
      margin-left: 50%;
    }

    &.display-50-left,
    &.display-50.left {
      figcaption,
      .share-links {
        color: $black;
      }
    }
    figcaption {
      padding: 15px;
      padding-top: 80px;
    }
    .share-links {
      padding: 15px;
      left: auto;
      right: 0;
      text-align: right;
      a {
        margin-left: $rhythm;
      }
    }
  }
}

.pictime {
  width: 100%;
}