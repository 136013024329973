html {
  font-size: $base-font-size + 0px;
  line-height: $base-line-height;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
  font-family: helvetica, sans-serif;
  font-weight: 400;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  &.state-page-switch {
    // HACK - super safari hack - prevent jump
    height: 1000000vh;
  }
  &::before {
    content: '';
    // @include fit-parent;
    right: 0;
    top: -50vh; //
    bottom: -50vh;
    position: fixed;
    z-index: 0;
    // opacity: .2;
    transition: $page-switch-time background-color;
    background: $secondary-color;
    .state-focus-navigation & {
      mix-blend-mode: lighten;
      z-index: 140;
    }

    @include phone {
      left: 0;
    }
    @include tablet-plus {
      left: 50%;
    }
  }
  @include tablet-plus {
    &::after {
      content: '';
      // @include fit-parent;
      left: 0;
      top: -50vh; //
      bottom: -50vh;
      position: fixed;
      z-index: 1;
      // opacity: .2;
      transition: $page-switch-time background-color;
      background: $primary-color;
      right: 50%;
    }
  }
  transition: $page-switch-time color;
  color: $primary-color;
  a {
    color: inherit;
  }

  // &.state-focus-navigation::before,
  // &.state-focus-navigation::after {
  //   mix-blend-mode: lighten;
  //   z-index: 140;
  // }
}

#header {
  .brand {
    position: relative;
    z-index: 100;
    transition: 400ms opacity;
    transform: translateZ(0);
    opacity: 1;
    @include set-font('content');
    color: $black;
    a {
      @include hover-underline($black, true);
    }
    p:not(:nth-child(1)):not(:nth-child(3)) {
      margin: 0;
    }
    .state-page-lock & {
      opacity: 0;
      pointer-events: none;
    }
  }
  .primary-navigation {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    // .state-focus-navigation & {
    //   z-index: 10000;
    // }
    .level-2 {
      a {
        color: $black;
      }
    }
  }
  @include phone {
    // @#header
    height: #{$rhythm * 4};
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .brand {
      // padding-top:20vh;
      // padding-bottom: 20vh;
      // transform-origin: 50% 50%;
      // transform: translateZ(0) rotate(90deg);
      // text-align: center;
      // p {
      //   margin: 10px;
      // }
      display: none;
    }
    .primary-navigation {
      > ul > li {
        text-align: right;
        padding-right: phone-inset();
        > a {
          pointer-events: all;
          @include set-font('content');
        }
        // &:nth-child(1) {
        //   width: 100vw;
        // }
        // &:nth-child(2) {
        //   width: 100vh;
        // }
        // &:nth-child(3) {
        //   width: 100vw;
        // }
        // &:nth-child(4) {
        //   width: 100vh;
        // }
        > ul {
          display: none;
        }
      }
    }
  }
  @include tablet-plus {
    // @#header
    .brand {
      padding-top: tablet-plus-inset() * 3;
      @include prefix(12);
      padding-left: #{tablet-plus-inset() / 2};
    }
    .primary-navigation {
      > ul > li {
        position: absolute;
        pointer-events: all;
        transition: 400ms opacity;
        opacity: 1;
        padding: 10px;
        .state-focus-navigation & {
          opacity: 1;
        }
        .state-unfocus-navigation & {
          @include tablet-plus {
            opacity: 0.1;
          }
        }
        &:nth-child(1) {
          top: 0;
          left: 0;
          transform: translateY(0%);
          @include tablet-plus {
            color: $secondary-color;
          }
        }
        &:nth-child(2) {
          top: 0;
          right: 0;
          transform-origin: 100% 0;
          transform: rotate(90deg)  translateX(100%) translateY(0%);
        }
        &:nth-child(3) {
          bottom: 0;
          right: 0;
          transform-origin: 100% 0;
          transform: rotate(180deg) translateX(100%) translateY(-100%);
        }
        &:nth-child(4) {
          top: 0;
          left: 0;
          transform-origin: 0 0;
          transform: rotate(-90deg) translateX(-100vh);
          @include tablet-plus {
            color: $secondary-color;
          }
        }
        > a {
          @include set-font('header-h1');
        }
        > ul {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          text-align: center;
          opacity: 1;
          transition: 200ms opacity;
          white-space: nowrap;
          .state-unfocus-navigation & {
            opacity: 0;
          }
          > li {
            display: inline-block;
            margin-left: $rhythm / 4;
            margin-right: $rhythm / 4;
            @include set-font('content');
            a {
              // @include hover-underline($primary-color, 'true');
              .no-touchevents &:hover {
                opacity: .6;
              }
              margin-top: 4px;
              &.current {
                // border-bottom: 1px solid;
                opacity: .6;
              }
            }
          }
        }
      }
    }
  }
}

#content {
  position: relative;
  z-index: 10;
  // pointer-events: none;
  // a {
  //   pointer-events: all;
  // }
  article {
    width: 100%;
    // &:not(.journal-content) {
      @include grid-container;
    // }
    > *:not(.blocks),
    .blocks > * {
      transform: translateZ(0); //hardware accelerated
      transition: 400ms opacity;
      @include phone {
        // opacity: .1;
      }
      @include tablet-plus {
        opacity: 1;
      }
      .state-focus-navigation &,
      .state-show-caption.state-focus-navigation &:not(.state-selected-caption),
      .state-show-caption &:not(.state-selected-caption) {
        opacity: .1;
        pointer-events: none;
        // filter: grayscale(100%);
      }
      .state-unfocus-navigation & {
        opacity: 1;
      }
      .state-page-lock & {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  @include phone {
    // @#content
    // padding-top: calc(#{phone-inset() * 2} + .9em);
    padding-top: phone-inset();
    // padding-top: 10vh;
  }
  @include tablet-plus {
    // @#content
    // padding-top: calc(#{tablet-plus-inset() * 2} + .9em);
    padding-top: tablet-plus-inset();
    // padding-top: 10vh;
  }
}


#footer {
  // height: 100vh;
  .brand {
    position: relative;
    z-index: 100;
    opacity: 1;
    transition: 400ms opacity;
    transform: translateZ(0);
    @include set-font('content');
    color: $black;
    a {
      @include hover-underline($black, true);
    }
    // p:not(:nth-child(1)):not(:nth-child(3)) {
    //   margin: 0;
    // }
    .state-page-lock & {
      opacity: 0;
      pointer-events: none;
    }
  }
  @include phone {
    // @#footer
    .brand {
      padding-top: 20vh;
      padding-bottom: 20vh;
      // @include prefix(4);
      text-align: center;
      // transform-origin: 50% 50%;
      // transform: translateZ(0) rotate(-90deg);
      p {
        margin: 10px;
      }
    }
  }
  @include tablet-plus {
    // @#footer
    height: 100vh;
    .brand {
      padding-top: tablet-plus-inset() * 3;
      @include prefix(12);
      padding-left: #{tablet-plus-inset() / 2};
    }
  }
  @include tablet {
    .brand {
      padding-top: 30vh;
    }
  }
}
