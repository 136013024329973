.about-content {
  @include tablet-plus {
    .primary-image {
      // @include prefix();
      @include cols(18);
    }
    .secondary-image {
      @include prefix(5);
      @include cols(19);
    }

    .words,
    .other-words {
      display: inline-block;
      vertical-align: middle;
    }
  }
  @include desktop-plus {
    .primary-image {
      // @include prefix();
      @include cols(18);
    }
    .secondary-image {
      @include prefix(6);
      @include cols(18);
    }
  }
}
