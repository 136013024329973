.contact-content {
  .contact-details {
    text-transform: uppercase;
    p {
      margin-bottom: .6em;
      // &:nth-child(odd) {
      //   text-align: left;
      // }
      // &:nth-child(even) {
      //   text-align: right;
      // }
    }
    a {
      @include hover-underline($black, true);
    }
  }
  .introduction.display-left {
    @include tablet-plus {
      position: sticky;
      top: 6rem;
    }
  }
  .introduction,
  .other-words {
    display: inline-block;
    vertical-align: top;
  }
  .questions {
    dt {
      margin-bottom: $rhythm;
    }
    dd {
      margin: 0;
      margin-bottom: $rhythm * 3;
      // font-style: italic;
    }
  }
}
