.journal-content {
  // .introduction {
  //   margin-top: 30vh;
  //   margin-bottom: 5vh;
  // }
  .page {
    // .page-items {
    //   display: flex;
    //   flex-direction: row;
    //   flex-wrap: wrap;
    // }
    .page-item {
      margin-bottom: 20vh;
      figure {
        position: relative;
        z-index: 0;
        width: 100%;
        margin-bottom: $rhythm;
      }
      header {
        position: relative;
        z-index: 2;
        color: $black;
        h1 {
          max-width: 9.4em;
          transition: 2000ms color;
          // color: $primary-color;
        }
        h2 {
          // @include set-font('h2');
          text-transform: uppercase;
          letter-spacing: .5em;
          @include font(15);
          // @include set-font('h2');
          margin-bottom: $rhythm * .5;
          .plus {
            position: relative;
            font-size: .8;
            top: -.08em;
          }
        }
        .date {
          // display: block;
          // text-align: right;
          // text-transform: uppercase;
          letter-spacing: .5em;
          @include font(10);
          // opacity: .3;
        }
      }
      @include phone {
        // @.page-item
        &:nth-child(odd) {
          @include prefix(2);
        }
        &:nth-child(even) {
          @include suffix(2);
        }
      }
      @include tablet-plus {
        // @.page-item
        &:nth-child(odd) {
          @include prefix(6);
          header {
            @include prefix(6.5, 18);
            @include suffix(2, 18);
          }
        }
        &:nth-child(even) {
          @include suffix(6);
          // @include prefix(1);
          header {
            text-align: right;
            @include prefix(2, 18);
            @include suffix(6.5, 18);
          }
        }
      }
    }
  }
}
