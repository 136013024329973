.journal-article-content {
  .primary-image {
    @include tablet-plus {
      // @.pramary-image
      @include prefix(4);
      @include cols(20);
    }
  }
  > header {
    position: relative;
    z-index: 2;
    margin-top: 10vh;
    margin-bottom: 10vh;
    h1,
    .date {
      @include set-font('h2');
    }
    .date {
      display: block;
      text-align: right;
      margin-bottom: $rhythm * 3;
      // opacity: .3;
    }
    @include phone {
      // header
      @include prefix(1);
      @include suffix(1);
    }
    @include tablet-plus {
      // header
      h1 {
        color: $black;
        // @include prefix(4);
        @include cols(12);
      }
    }
    // @include desktop-plus {
    //   // header
    //   .summary {
    //     margin-left: $rhythm * 5;
    //   }
    // }
  }
}
